
export function App() {



      return (
            <div className="App">
                  <div class="container">

                        <div>
                              <h1>wize</h1>
                        </div>
                        <div style={{ color: 'white', marginLeft: 50, paddingLeft: 25, borderLeft: '2px solid rgba(255,255,255,0.1)' }}>
                              c/o Onno Group Limited<br />
                              Suite 22 Trym Lodge<br />
                              1 Henbury Road<br />
                              Bristol<br />
                              BS9 3HQ<br />
                              admin@wize.co.uk<br />
                        </div>
                  </div>
            </div>
      )
}
